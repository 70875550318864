$(function() {
    $( "body" ).on( "click", ".closeMm", function() {
        $(".mobileMenu").removeClass('mmShown');
        $(".mobileMenuOverlay").fadeOut(200);
        $("body").removeClass("noScroll");
    });
    $( "body" ).on( "click", ".headerMenuSm", function() {
        $(".mobileMenu").addClass('mmShown');
        $(".mobileMenuOverlay").fadeIn(200);
        $("body").addClass("noScroll");
    });
    $( "body" ).on( "click", ".navLocation , .changeLoation , .deliveryLoc , .headerMenuLocation", function() {
        $(".locationOverlay").fadeIn(200);
        $("body").addClass("noScroll");
        $(".selectLocation").slideDown();
        $(".mobileMenu").removeClass('mmShown');
        $(".mobileMenuOverlay").fadeOut(200);
        $("body").removeClass("noScroll");
    });
     $( "body" ).on( "click", ".locationOverlay , .closeSelectLocation", function() {
         $(".locationOverlay").fadeOut(200);
         $("body").removeClass("noScroll");
         $(".selectLocation").slideUp();
     });
    $( "body" ).on( "click", ".selectLocationCitiesHolder a", function() {
        $(this).addClass("active");
        $(this).siblings().removeClass("active");
    });
    $(document).keyup(function(e) {
        if (e.key === "Escape") {
            $(".locationOverlay").fadeOut(200);
            $("body").removeClass("noScroll");
            $(".mobileMenu").removeClass('mmShown');
            $(".mobileMenuOverlay").fadeOut(200);
            $(".selectLocation").slideUp();
       }
   });

    $( "body" ).on( "click", ".switch", function() {
        $(this).find(".switchClicker").toggleClass("active");
    });

    /*$( "body" ).on( "click", ".cartCountLess", function() {
    var cartInput = $(this).siblings("input");
        if (cartInput.val() > 1) {
        cartInput.val(cartInput.val() - 1);
    }
    });

    $( "body" ).on( "click", ".cartCountMore", function() {
        var cartInput = $(this).siblings("input");
        cartInput.val(parseInt(cartInput.val()) + 1);
    });*/

    $( "body" ).on( "click", ".savedAddressItem", function() {
        $(this).addClass("active");
        $(this).siblings().removeClass("active");
    });

    $( "body" ).on( "click", ".paymentItemHeader", function() {
        $(this).closest(".paymentOption").addClass("active");
        $(this).closest(".paymentOption").siblings().removeClass("active");
    });

    $( "body" ).on( "click", ".savedCard", function() {
        $(this).addClass("active");
        $(this).siblings().removeClass("active");
    });

    $( "body" ).on( "click", ".slotItem", function() {
        $(this).closest(".slotCol").addClass("active");
        $(this).closest(".slotCol").siblings().removeClass("active");
        $(".gotoPayments").removeAttr("disabled");
    });

    $( "body" ).on( "click", ".getPayTmOtp", function() {
        $(".paytmNumber").hide();
        $(".paytmOtp").show();
    });

    $( "body" ).on( "click", ".nBankItem", function() {
        $(".nBankItem").removeClass("active");
        $(this).addClass("active");
    });

    function openLogin() {
        $(".loginModalOverlay").fadeIn();
        $(".loginModal").addClass("lmShown");
        $("body").addClass("noScroll");
    }

    function closeLogin() {
        $(".loginModalOverlay").fadeOut();
        $(".loginModal").removeClass("lmShown");
        $("body").removeClass("noScroll");
    }

    $( "body" ).on( "click", ".openLoginModal,.smLoginModal", function() {

        openLogin();
        $(".mobileMenu").removeClass('mmShown');
        $(".mobileMenuOverlay").fadeOut(200);
        $("body").removeClass("noScroll");
    });

    $( "body" ).on( "click", ".toggleLoginModal", function() {
        openLogin();
        closeCart()
        $(".mobileMenu").removeClass('mmShown');
        $(".mobileMenuOverlay").fadeOut(200);
        $("body").removeClass("noScroll");
    });

    $( "body" ).on( "click", ".mobileMenu a", function() {
        $(".mobileMenu").removeClass('mmShown');
        $(".mobileMenuOverlay").fadeOut(200);
        $("body").removeClass("noScroll");
    });

    $( "body" ).on( "click", ".loginModalOverlay, .closeLM", function() {
        closeLogin();
    });

    function openCart() {
        $(".cartModalOverlay").fadeIn();
        $(".cartModal").addClass("cartShown");
        $("body").addClass("noScroll");
    }

    function closeCart() {
        $(".cartModalOverlay").fadeOut();
        $(".cartModal").removeClass("cartShown");
        $("body").removeClass("noScroll");
    }

    $( "body" ).on( "click", ".navCart , .openCart", function() {
        openCart();
    });
    $( "body" ).on( "click", ".closeCart , .gotoSelectAddress", function() {
        closeCart();
    });

    $( "body" ).on( "click", ".accordion button", function() {
        $(this).closest(".card-header").siblings("div").slideToggle(200);
    });

    $( "body" ).on( "click", ".topNavBulkOrder", function() {
        openBulkOrder();
    });

    $( "body" ).on( "click", ".closeBo,.bulkOrderOverlay,.placeBoOrder", function() {
        closeBulkOrder();
    });

    $( "body" ).on( "click", ".goTo-boOtp", function() {
        $(".boPhone,.boOrder").hide();
        $(".boOtp").show();
    });

    $( "body" ).on( "click", ".goTo-boOrder", function() {
        $(".boPhone,.boOtp").hide();
        $(".boOrder").show();
    });

    function openBulkOrder() {
        $(".bulkOrderOverlay").fadeIn();
        $(".bulkOrderModal").addClass("boShown");
        $("body").addClass("noScroll");
    }

    function closeBulkOrder() {
        $(".bulkOrderOverlay").fadeOut();
        $(".bulkOrderModal").removeClass("boShown");
        $("body").removeClass("noScroll");
    }

});

$(window).scroll(function() {
    if ($(window).width() > 580) {
        if ( $(window).scrollTop() > 171 ) {
            $("header").addClass("headerScrolled");
        } else {
            $("header").removeClass("headerScrolled");
        }
    }
});
